<template>
  <ValidationProvider tag="div" class="employee-avatar-input">
    <EmployeeAvatar
      class="mb-4"
      size="100"
      text="h5"
      :src-url="avatarUrl"
      :employee="employee"
      hide-status
    />

    <div class="d-flex">
      <VxBtn text color="dark" @click="onChange">
        {{ avatarUrl ? "Edit" : "Add photo" }}
      </VxBtn>
      <v-slide-x-transition hide-on-leave>
        <VxBtn v-if="avatarUrl" text color="dark" @click="onRemove">
          Remove
        </VxBtn>
      </v-slide-x-transition>
    </div>

    <v-file-input
      ref="fileInput"
      v-model="avatarFile"
      accept="image/png, image/jpeg"
      class="d-none"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </ValidationProvider>
</template>

<script>
import { VxBtn } from "@/core-ui";
import { ValidationProvider } from "vee-validate";
import EmployeeAvatar from "@/components/employees/EmployeeAvatar.vue";

export default {
  name: "EmployeeAvatarInput",
  components: { EmployeeAvatar, ValidationProvider, VxBtn },
  inheritAttrs: false,
  model: { event: "change" },
  props: {
    employee: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    avatarFile: null,
  }),
  computed: {
    avatarUrl() {
      return this.avatarFile
        ? URL.createObjectURL(this.avatarFile)
        : this.employee.avatarUrl;
    },
  },
  methods: {
    onChange() {
      this.$refs.fileInput.$refs.input.click();
    },
    onRemove() {
      this.avatarFile = null;
    },
  },
};
</script>

<style lang="scss">
.employee-avatar-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
