/**
 * @typedef {Object} SubscriptionOneOffResult
 * @property {Boolean} success - defines if subscription call passed succesfully
 * @property {Array<String>} errors - array of errors returned if subscription call failed
 */

/**
 * @typedef {Object} SubscriptionOneOffOptions
 * @property {String} name - name of subscription object
 * @property {(result: SubscriptionOneOffResult) => void} [resolve] - callback function executed only once on successfull subscription call
 * @property {(error: any) => void} [reject] - callback function executed only once on failed subscription call
 */

/**
 * Subscribe to Smart GraphGQL subscription changes and unsubscribe when first result is returned
 * @param {import("vue").default} vm - instance of Vue component
 * @param {SubscriptionOneOffOptions} options - define options
 * @returns {import("apollo-client/util/Observable").Subscription}
 */
export function subscribeOneOff(vm, options) {
  const { name: subscriptionName } = options;
  const observer = vm.$apollo.subscriptions[subscriptionName]?.observer;

  if (!observer) {
    throw new Error(
      `Subscription with name ${subscriptionName} or her observer does not exist.`
    );
  }

  const subscription = observer.subscribe({
    next: ({ data }) => {
      options.resolve?.call(vm, data[subscriptionName]);
      subscription.unsubscribe();
    },
    error: (error) => {
      options.reject?.call(vm, error);
      subscription.unsubscribe();
    },
  });

  return subscription;
}
