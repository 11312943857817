<template>
  <v-dialog
    :value="true"
    :fullscreen="isMobile"
    :max-width="maxWidth"
    @click:outside="cancel"
    @keydown.esc="cancel"
  >
    <v-card
      data-testid="employeeSetPinDialog"
      :loading="pinLoading"
      class="overflow-hidden"
    >
      <v-toolbar flat>
        <v-btn icon @click="cancel">
          <v-icon>$back</v-icon>
        </v-btn>
        <v-toolbar-title> Set a new PIN </v-toolbar-title>
      </v-toolbar>
      <v-divider />
      <v-card-text class="pa-4">
        <PinPad
          ref="pinPad"
          :pin-length="4"
          :disabled="pinLoading"
          :error-message.sync="pinErrorMessage"
          @finish="ok"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PinPad from "@/components/pin-pad/PinPad.vue";
import { useResponsiveness } from "@/core-ui";

export default {
  name: "EmployeeSetPinDialog",
  components: { PinPad },
  mixins: [useResponsiveness()],
  inheritAttrs: false,
  props: {
    maxWidth: {
      type: Number,
      default: 400,
    },
  },
  data: () => ({
    pinLoading: false,
    pinErrorMessage: "",
  }),
  methods: {
    ok(value) {
      this.pinErrorMessage = "";
      this.pinLoading = true;
      this.$emit("ok", {
        value,
        resolve: () => {
          this.hide();
        },
        setErrors: (errors) => {
          this.pinLoading = false;
          this.pinErrorMessage = errors;
        },
      });
    },
    cancel() {
      this.hide();
      this.$emit("cancel");
    },
    hide() {
      this.pinLoading = false;
      this.pinErrorMessage = "";
      this.$refs.pinPad.clear();
    },
  },
};
</script>
