import Vue from "vue";

/**
 * @typedef {Object} FormObjectOptions
 * @property {String} prop - define prop to pass down form object values
 * @property {Object} default - the default form object value when initialized or `prop` value is not defined
 * @property {Boolean} [prune = true] - prune form object keys that are not defined in default value
 * @property {Boolean} [sync = false] - watch form object value changes and emit update sync event
 * @property {String} [syncEvent] - define custom sync event to emit when form object value changes
 * @property {String | Function} [handler] - define custom handler for form object values
 */

/**
 * Define reactive form object on Vue instance
 * @param {FormObjectOptions} options - the options to define form object usage
 * @returns extended Vue instance
 */
export function useFormObject(options) {
  const {
    prop,
    prune = true,
    sync = false,
    syncEvent = `update:${prop}`,
    handler = null,
  } = options;

  const pruneNonDefaultValues = (value) =>
    Object.fromEntries(
      Object.entries(value).filter(([key]) => key in options.default)
    );

  return Vue.extend({
    props: {
      [prop]: {
        type: Object,
        default: () => ({ ...options.default }),
      },
    },
    data: () => ({
      form: { ...options.default },
    }),
    watch: {
      [prop]: {
        immediate: true,
        handler(value) {
          const formData = {
            ...this.form,
            ...(prune === true && value ? pruneNonDefaultValues(value) : value),
          };

          handler && typeof handler === "string"
            ? this[handler](formData)
            : handler?.call(this, formData);

          this.form = formData;
        },
      },
    },
    created() {
      if (sync) {
        this.$watch("form", (value) => this.$emit(syncEvent, { ...value }), {
          deep: true,
        });
      }

      Object.defineProperty(this.form, "$reset", {
        value: function () {
          for (let key in options.default) {
            this[key] = options.default[key];
          }
        },
      });
    },
  });
}
