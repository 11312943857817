<template>
  <div class="team">
    <!-- TODO: handle manager content -->
    <ManagerContent v-if="!useFabAddButton && showEmployeeAddButton">
      <!-- TODO: this manager content and test id and whole this piece should be gone when we rethink the whole layout and components reusability -->
      <VxBtn
        outlined
        data-testid="teamNewEmployeeBtn-onboarding"
        @click="onNewClick"
      >
        <v-icon left>$plus</v-icon>
        Add new employee
      </VxBtn>
    </ManagerContent>
    <EmployeeDataList
      :employees="employees"
      :query="employeeQuery"
      :loading="$apollo.queries.employees.loading"
      @click:employee="onEmployeeClick"
    />
    <EmployeeDialog
      v-model="showNewDialog"
      title="New employee"
      @ok="onEmployeeCreate"
    />
    <EmployeeDialog
      v-model="showEditDialog"
      :employee="selectedEmployee"
      @cancel="onEmployeeCancel"
      @remove="onEmployeeRemove"
      @ok="onEmployeeUpdate"
      @set-pin="onSetPin"
    />
    <EmployeeSetPinDialog
      v-if="showSetPinDialog"
      @cancel="onEmployeeSetPinDialogCancel"
      @ok="onEmployeeSetPin"
    />
    <!-- TODO: handle manager content -->
    <ManagerContent v-if="useFabAddButton && showEmployeeAddButton">
      <VxBtn
        fab
        fixed
        bottom
        right
        data-testid="teamNewEmployeeBtn"
        @click="onNewClick"
      >
        <v-icon>$plus</v-icon>
      </VxBtn>
    </ManagerContent>
  </div>
</template>

<script>
import { VxBtn } from "@/core-ui";
import { mutationUpdate } from "@/utils/graphql";
import { hasSnackbarAccess } from "@/mixins/snackbar";
import {
  DRS_EMPLOYEES_QUERY,
  EMPLOYEES_QUERY,
  EMPLOYEE_CREATE,
  EMPLOYEE_UPDATE,
  EMPLOYEE_DELETE,
  EMPLOYEE_SET_PIN,
} from "./graphql";

import EmployeeDialog from "./components/EmployeeDialog.vue";
import EmployeeDataList from "./components/EmployeeDataList.vue";
import EmployeeSetPinDialog from "./components/EmployeeSetPinDialog.vue";
import ManagerContent from "@/components/core/ManagerContent";

// TODO: resolve setting of employee avatar via GQL mutation

export default {
  name: "TeamView",
  components: {
    EmployeeDialog,
    EmployeeDataList,
    EmployeeSetPinDialog,
    ManagerContent,
    VxBtn,
  },
  provide() {
    return {
      getDrsEmployees: () => this.drsEmployees,
    };
  },
  mixins: [hasSnackbarAccess],
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
    parsedFilter: {
      type: Object,
      default: () => ({}),
    },
    // TODO: remove props: useFabAddButton, showEmployeeAddButton -> when we rethink the whole layout and components reusability
    useFabAddButton: {
      type: Boolean,
      default: true,
    },
    showEmployeeAddButton: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    showNewDialog: false,
    showEditDialog: false,
    showSetPinDialog: false,
    selectedEmployee: {},
  }),
  apollo: {
    employees: {
      fetchPolicy: "cache-and-network",
      query: EMPLOYEES_QUERY,
      variables() {
        return { storeId: this.storeId };
      },
      skip() {
        return !this.storeId;
      },
    },
    drsEmployees: {
      query: DRS_EMPLOYEES_QUERY,
      variables() {
        return { storeId: this.storeId };
      },
      skip() {
        return !this.storeId;
      },
    },
  },
  computed: {
    employeeQuery() {
      return this.parsedFilter.filterPresent
        ? this.parsedFilter.data
        : undefined;
    },
  },
  methods: {
    refetchEmployees() {
      this.$apollo.queries.employees.refetch();
    },
    refetchDrsEmployees() {
      this.$apollo.queries.drsEmployees.refetch();
    },
    onEmployeeClick(employee) {
      this.selectedEmployee = { ...employee };
      this.showEditDialog = true;
    },
    onNewClick() {
      this.showNewDialog = true;
    },
    async onEmployeeCreate({ values, resolve, setErrors }) {
      try {
        const { avatarFile, ...input } = values;
        const { data } = await this.$apollo.mutate({
          mutation: EMPLOYEE_CREATE,
          variables: { input },
          update: mutationUpdate({
            query: EMPLOYEES_QUERY,
            queryListProp: "employees",
            queryVariables: { storeId: this.storeId },
            mutationProp: "createEmployee",
            mutationObjectProp: "employee",
          }),
        });

        const { errors, employee } = data.createEmployee;

        if (errors?.length > 0) {
          setErrors(errors);
          return;
        }

        await this.uploadEmployeeAvatar(employee, avatarFile);
        this.showSnackbar({ text: "Succesfully added employee" });
        resolve();
      } catch {
        setErrors(["Error has happened while trying to add an employee"]);
      }
    },
    async onEmployeeUpdate({ values, resolve, setErrors }) {
      try {
        const { avatarFile, ...input } = values;
        const { data } = await this.$apollo.mutate({
          mutation: EMPLOYEE_UPDATE,
          variables: { input: { ...input, id: this.selectedEmployee.id } },
        });

        const { errors, employee } = data.updateEmployee;

        if (errors?.length > 0) {
          setErrors(errors);
          return;
        }

        await this.uploadEmployeeAvatar(employee, avatarFile);
        this.showSnackbar({ text: "Succesfully updated employee" });
        this.selectedEmployee = {};
        resolve();
      } catch (e) {
        setErrors(["Error has happened while trying to update an employee"]);
      }
    },
    async onEmployeeRemove({ id, fullName }) {
      const confirm = await this.$dialog.confirm({
        title: "Delete employee",
        message: `Are you sure you want to delete employee with name ${fullName}?`,
      });

      if (!confirm) {
        return;
      }

      try {
        await this.$apollo.mutate({
          mutation: EMPLOYEE_DELETE,
          variables: { id },
        });

        this.showSnackbar({ text: "Succesfully deleted employee" });
        this.$apollo.queries.employees.refetch();
        this.showEditDialog = false;
        this.selectedEmployee = {};
      } catch {
        this.showSnackbar(
          "Error has happened while trying to delete an employee"
        );
      }
    },
    onEmployeeCancel() {
      this.selectedEmployee = {};
    },
    uploadEmployeeAvatar(employee, file) {
      if (!(file instanceof File)) {
        return Promise.resolve();
      }

      const url = `/${this.storeId}/employees/${employee.id}`;
      const payload = this.createEmployeeAvatarPayload(employee, file);
      return this.$http
        .patch(url, payload)
        .catch((e) => console.log(e.message));
    },
    createEmployeeAvatarPayload(employee, file) {
      if (!(file instanceof File)) {
        throw new Error("File is not valid");
      }

      const data = new FormData();
      const { id, firstName, lastName } = employee;
      const fileName = `
          ${Date.now()}_${id}_${firstName.charAt(0)}${lastName.charAt(0)}
        `;

      data.append("employee[avatar]", file, fileName.trim());
      return data;
    },
    onSetPin() {
      this.showSetPinDialog = true;
    },
    onEmployeeSetPinDialogCancel() {
      this.showSetPinDialog = false;
    },
    async onEmployeeSetPin({ value, resolve, setErrors }) {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: EMPLOYEE_SET_PIN,
          variables: {
            employeeId: this.selectedEmployee.id,
            pin: value,
          },
          context: { skipEmployeeValidation: true },
        });

        const { errors } = data.setPin;

        if (errors?.length > 0) {
          setErrors(errors.join(", "));
          return;
        }

        this.showSnackbar({ text: "Succesfully set PIN" });
        this.showSetPinDialog = false;
        resolve();
      } catch (error) {
        setErrors("Error has happened while trying to set PIN");
      }
    },
  },
};
</script>
