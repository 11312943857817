/**
 * This callback is a returned event listener
 * @callback MutationUpdateCallback
 * @param {number} nameOfNumberParam
 * @param {string} nameOfStringParam
 */

/**
 * @typedef {Object} MutationUpdateOptions
 * @property {Object} query - query in cache which should be updated
 * @property {Object} queryVariables - variables for the query being updated. Should be the same ones as when executing query.
 * @property {String} mutationProp  - name of propery in response which holds the whole mutation data
 * @property {String} mutationObjectProp - name of propery in response which holds the value of new object
 * @property {String} queryListProp - name of propery in cache which holds list of objects. The same value returned from query.
 */
/**
 *
 * @param {MutationUpdateOptions} options
 * @returns {MutationUpdateCallback} - update callback for apollo mutation
 */
export const mutationUpdate = (options) => {
  return (store, response) => {
    updateCacheWithNewValue({
      cache: store,
      mutationData: response?.data[options.mutationProp],
      ...options,
    });
  };
};

const updateCacheWithNewValue = (options = {}) => {
  const {
    cache,
    mutationData,
    query,
    queryVariables,
    mutationObjectProp,
    queryListProp,
  } = options;

  // TODO - think about some advanced features like matching all via __typename
  //  - which would mean we don't need mutationObjectProp and queryListProp

  if (!mutationData || !cache || !query) {
    throw "No mutation data, cache or query";
  }

  if (mutationData?.errors && mutationData.errors.length > 0) {
    return;
  }
  const newCachedValue = mutationData[mutationObjectProp];

  const queryCache =
    cache.readQuery({
      query: query,
      variables: queryVariables,
    }) || [];

  const cachedValues = queryCache[queryListProp];
  if (!cachedValues) {
    return;
  }

  const hasCachedValue = cachedValues.some((cv) => cv.id === newCachedValue.id);
  if (hasCachedValue) {
    return;
  }

  // TODO - involve possibility to sort it
  cachedValues.push(newCachedValue);
  cache.writeQuery({
    query: query,
    variables: queryVariables,
    data: { [queryListProp]: cachedValues },
  });
};
