import gql from "graphql-tag";
import { merge } from "lodash";
import { Role } from "@/constants";
import { mapGetters } from "vuex";
import { STORE_EMPLOYEES } from "@/graphql/employee/queries.gql";

export const employeesWithDrsCode = {
  apollo: {
    employees: {
      query: STORE_EMPLOYEES,
      variables() {
        return {
          storeId: this.$route.params.storeId,
        };
      },
      skip() {
        return !this.$route.params.storeId;
      },
    },
  },
  computed: {
    employeesWithDrsCode() {
      return (this.employees || []).filter(
        ({ drsEmployeeCode }) => drsEmployeeCode
      );
    },
  },
};

export const hasStoreEmployeesAccess = {
  data: () => ({
    employees: [],
  }),
  apollo: {
    employees: {
      query: STORE_EMPLOYEES,
      variables() {
        return {
          storeId: this.$route.params.storeId,
        };
      },
      skip() {
        return !this.$route.params.storeId;
      },
    },
  },
};

export const hasActiveEmployeeIdAccess = {
  computed: {
    ...mapGetters("auth", ["isTeamMode"]),
    ...mapGetters("auth", ["activeEmployeeId"]),
  },
};

export const hasActiveEmployeeAccess = merge(
  {},
  hasStoreEmployeesAccess,
  hasActiveEmployeeIdAccess,
  {
    computed: {
      ...mapGetters("auth", ["activeEmployeeId"]),
      computedActiveEmployee() {
        return this.employees?.find(
          (employee) =>
            employee.id.toString() == this.activeEmployeeId?.toString()
        );
      },
      // Determine the current navigator's role by:
      // 1. Get the active team mode employee
      // - or
      // 2. Get the current user's employee's role
      // - or
      // 3. Get the current user's role
      // - otherwise
      // 4. Return `user`
      /*
       * TODO
       * SHOULD BE: They have manager permission IF
       * 1. the current user role is manager, district, or owner
       * or
       * 2. the current employee has is_manager
       */
      computedActiveEmployeeRole() {
        return this.computedActiveEmployee?.isManager
          ? Role.MANAGER
          : Role.USER;
      },
      // Used to determine what an employee can and can't do
      computedActiveEmployeeCan() {
        const managerOrHigher = this.computedActiveEmployeeRole >= Role.MANAGER;

        return {
          addShift: managerOrHigher,
          editShift: managerOrHigher,
          editNote: managerOrHigher,
          editNoteContent: managerOrHigher,
          editNoteAuthor: managerOrHigher,
          editNoteDates: managerOrHigher,
          editTask: managerOrHigher,
          deleteTask: managerOrHigher,
          seeManagerContent: managerOrHigher,
          actAsManager: managerOrHigher,
        };
      },
    },
  }
);

export const injectEmployees = {
  inject: {
    computedEmployees: {
      default: () => [],
    },
  },
  computed: {
    employees() {
      return this.computedEmployees();
    },
  },
};

export const injectClockedInEmployees = {
  inject: {
    computedEmployees: {
      default: () => [],
    },
  },
  computed: {
    clockedInEmployees() {
      return this.computedEmployees().filter(({ clockedIn }) => clockedIn);
    },
  },
};

// This mixin injects
// - computedActiveEmployee
// - computedActiveEmployeeCan (role-based stuff)
// - computedEmployees
//
// Then, defines a few computed properties, which
// makes accessing the injected properties accessible
// just like a typical computed property.
export const injectActiveEmployee = {
  inject: {
    computedActiveEmployee: {
      default: () => {},
    },
    computedActiveEmployeeCan: {
      default: () => {},
    },
    computedEmployees: {
      default: () => [],
    },
  },
  computed: {
    activeEmployee() {
      return this.computedActiveEmployee();
    },
    activeEmployeeCan() {
      return this.computedActiveEmployeeCan();
    },
    employees() {
      return this.computedEmployees();
    },
  },
};

// Adds a method to the Vue instance which allows querying
// the Apollo employees cache for a given id and fieldset
export const queryEmployee = {
  methods: {
    queryEmployee(id, fields = ["id", "fullName"]) {
      if (!id) return undefined;

      const query = gql`
        query MIXIN_EMPLOYEE($id: ID!) {
          employee(id: $id) {
            ${fields.length && fields.join("\n")}
          }
        }
      `;
      id = id.toString();
      return this.$apollo.query({
        query,
        variables: { id },
        fetchPolicy: "cache-first",
      });
    },
  },
};
