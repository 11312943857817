<template>
  <VxDataListItem
    data-testid="employeeDataListItem"
    @click="$emit('click', employee)"
  >
    <template #desktop>
      <td>
        <EmployeeAvatar :employee="employee" size="35" hide-status />
      </td>
      <td>{{ employee.fullName }}</td>
      <td>{{ phoneNumber }}</td>
      <td class="text-truncate" :title="email">{{ email }}</td>
      <td>{{ drsEmployeeCode }}</td>
      <!-- TODO: handle manager content -->
      <ManagerContent>
        <td>
          {{ roleName }}
        </td>
      </ManagerContent>
      <!-- TODO: handle manager content -->
      <ManagerContent>
        <td>
          <VxCheckbox
            v-model="employee.hasRemoteAccess"
            class="mt-0"
            hide-details
            disabled
          />
        </td>
      </ManagerContent>
    </template>
    <template #mobile>
      <EmployeeAvatar :employee="employee" list-item hide-status />
      <v-list-item-content>
        <v-list-item-title>
          {{ employee.fullName }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ phoneNumber }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-list-item-action-text>
          {{ employee.drsEmployeeCode }}
        </v-list-item-action-text>
      </v-list-item-action>
    </template>
  </VxDataListItem>
</template>

<script>
import { roles } from "@/constants";
import ManagerContent from "@/components/core/ManagerContent";
import EmployeeAvatar from "@/components/employees/EmployeeAvatar.vue";
import { VxDataListItem, formatPhoneNumber, VxCheckbox } from "@/core-ui";

export default {
  name: "EmployeeDataListItem",
  components: {
    ManagerContent,
    EmployeeAvatar,
    VxDataListItem,
    VxCheckbox,
  },
  props: {
    employee: {
      type: Object,
      required: true,
    },
    roles: {
      type: Array,
      default: () => roles,
    },
  },
  computed: {
    phoneNumber() {
      return formatPhoneNumber(this.employee.phoneNumber) || "-";
    },
    email() {
      return this.employee.email || "-";
    },
    drsEmployeeCode() {
      return this.employee.drsEmployeeCode || "-";
    },
    roleName() {
      return this.roles.find((x) => x.value === this.employee.userRoleId)?.text;
    },
  },
};
</script>
