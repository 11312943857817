<template>
  <v-card class="employee-card" data-testid="employeeCard" v-bind="$attrs">
    <div class="d-flex justify-center pt-4">
      <EmployeeAvatar text="h5" size="100" :employee="employee" hide-status />
    </div>
    <v-card-title class="justify-center mb-2">
      {{ employee.fullName }}
    </v-card-title>
    <v-card-subtitle class="text-center">
      {{ roleName }}
    </v-card-subtitle>
    <v-list class="px-4 pb-4">
      <v-list-item v-if="hasPhoneNumber">
        <v-list-item-icon>
          <v-icon>$phone</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ phoneNumber }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="hasEmail" :href="emailLink">
        <v-list-item-icon>
          <v-icon>$email</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ employee.email }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="hasEmergencyContact">
        <v-list-item-icon>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">$emergency</v-icon>
            </template>
            <span>Emergency contact</span>
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ employee.emergencyName }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ emergencyPhoneNumber }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="employee.drsEmployeeCode">
        <v-list-item-icon>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">$badgeAccount</v-icon>
            </template>
            <span>DRS Initials</span>
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ employee.drsEmployeeCode }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { roles } from "@/constants";
import { formatPhoneNumber } from "@/core-ui";
import EmployeeAvatar from "@/components/employees/EmployeeAvatar.vue";

export default {
  name: "EmployeeCard",
  components: { EmployeeAvatar },
  inheritAttrs: false,
  props: {
    employee: {
      type: Object,
      required: true,
    },
    roles: {
      type: Array,
      default: () => roles,
    },
  },
  computed: {
    phoneNumber() {
      return formatPhoneNumber(this.employee.phoneNumber);
    },
    hasPhoneNumber() {
      return !!this.employee.phoneNumber;
    },
    emailLink() {
      return this.hasEmail ? `mailto:${this.employee.email}` : undefined;
    },
    hasEmail() {
      return !!this.employee.email;
    },
    emergencyPhoneNumber() {
      return formatPhoneNumber(this.employee.emergencyPhone);
    },
    hasEmergencyContact() {
      return this.employee.emergencyName || this.employee.emergencyPhone;
    },
    roleName() {
      return this.roles.find((x) => x.value === this.employee.userRoleId)?.text;
    },
  },
};
</script>
