<template>
  <VxForm ref="form" data-testid="employeeForm" @submit="handleSubmit">
    <v-row class="pt-4">
      <v-col>
        <EmployeeAvatarInput
          v-model="form.avatarFile"
          name="avatarFile"
          :employee="form"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <VxTextField
          v-model="form.firstName"
          data-testid="employeeFormFirstNameField"
          label="First name"
          placeholder="eg. John"
          autocomplete="off"
          :autofocus="!employee.id"
          name="firstName"
          rules="required"
        />
      </v-col>
      <v-col cols="12" md="6">
        <VxTextField
          v-model="form.lastName"
          data-testid="employeeFormLastNameField"
          label="Last name"
          placeholder="eg. Doe"
          autocomplete="off"
          name="lastName"
          rules="required"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <VxTextField
          v-model="form.email"
          data-testid="employeeFormEmailField"
          label="Email"
          placeholder="eg. john.doe@example.com"
          autocomplete="off"
          name="email"
          rules="email|required_if:hasRemoteAccess,true"
        />
      </v-col>
      <v-col cols="12" md="6">
        <VxPhoneField
          v-model="form.phoneNumber"
          data-testid="employeeFormPhoneNumberField"
          label="Phone number"
          placeholder="eg. (212) 555-2323"
          autocomplete="off"
          name="phoneNumber"
          rules="na_phone"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <VxTextField
          v-model="form.emergencyName"
          data-testid="employeeFormEmergencyNameField"
          label="Emergency name"
          placeholder="eg. Jane Doe"
          autocomplete="off"
          name="emergencyName"
        />
      </v-col>
      <v-col cols="12" md="6">
        <VxPhoneField
          v-model="form.emergencyPhone"
          data-testid="employeeFormEmergencyPhoneField"
          label="Emergency phone"
          placeholder="eg. (212) 555-2323"
          autocomplete="off"
          name="emergencyPhone"
          rules="na_phone"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <VxAutocomplete
          v-model="form.drsEmployeeCode"
          data-testid="employeeFormDrsEmployeeCodeAutocomplete"
          label="DRS Employee"
          placeholder="Search for DRS employee"
          autocomplete="off"
          :items="drsEmployees"
          name="drsEmployeeCode"
        />
      </v-col>
      <v-col cols="12" md="6">
        <VxSelect
          v-model="form.userRoleId"
          data-testid="employeeFormUserRoleSelect"
          :items="roles"
          label="Access"
          rules="required"
          name="userRoleId"
          :disabled="isUserRoleDisabled"
          @change="onUserRoleChange"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <VxCheckbox
          v-model="form.hasRemoteAccess"
          data-testid="employeeFormRemoteAccessCheckbox"
          class="mt-0"
          label="Remote access"
          :disabled="isRemoteAccessDisabled"
          name="hasRemoteAccess"
        />
      </v-col>
    </v-row>
  </VxForm>
</template>

<script>
import { Role } from "@/constants";
import { useFormObject } from "@/mixins/useFormObject";
import EmployeeAvatarInput from "./EmployeeAvatarInput.vue";
import {
  VxForm,
  VxTextField,
  VxPhoneField,
  VxAutocomplete,
  VxCheckbox,
  VxSelect,
} from "@/core-ui";

export default {
  name: "EmployeeForm",
  inject: ["getDrsEmployees"],
  components: {
    EmployeeAvatarInput,
    VxForm,
    VxTextField,
    VxPhoneField,
    VxAutocomplete,
    VxCheckbox,
    VxSelect,
  },
  mixins: [
    useFormObject({
      prop: "employee",
      default: {
        id: null,
        firstName: null,
        lastName: null,
        fullName: null,
        phoneNumber: null,
        email: null,
        emergencyName: null,
        emergencyPhone: null,
        drsEmployeeCode: null,
        avatarFile: null,
        hasRemoteAccess: false,
        userRoleId: Role.USER,
      },
    }),
  ],
  props: {
    roles: {
      type: Array,
      default() {
        return [
          { text: "User", value: Role.USER },
          { text: "Manager", value: Role.MANAGER },
          { text: "District manager", value: Role.DISTRICT },
          { text: "Account Owner", value: Role.OWNER },
        ];
      },
    },
  },
  computed: {
    isUserRoleDisabled() {
      return this.employee.id === this.$store.state.auth.employeeId.toString();
    },
    isRemoteAccessDisabled() {
      return this.shouldHaveRemoteAccessByDefault(this.form.userRoleId);
    },
    drsEmployees() {
      const emp = this.getDrsEmployees?.call() ?? [];

      emp.sort((a, b) => b?.userid - a?.userid);
      return emp;
    },
  },
  created() {
    this.$watch(
      () => `${this.form.firstName ?? ""} ${this.form.lastName ?? ""}`,
      (value) => this.$set(this.form, "fullName", value.trim())
    );
  },
  methods: {
    submit() {
      this.$refs.form.submit();
    },
    reset() {
      this.form = this.$options.props.employee.default();
      this.$refs.form.reset();
    },
    handleSubmit(context) {
      this.$emit("submit", {
        ...context,
        values: { ...context.values, avatarFile: this.form.avatarFile },
      });
    },
    onUserRoleChange(value) {
      this.form.hasRemoteAccess = this.shouldHaveRemoteAccessByDefault(value);
    },
    shouldHaveRemoteAccessByDefault(roleId) {
      return [Role.DISTRICT, Role.OWNER].includes(roleId);
    },
  },
};
</script>
