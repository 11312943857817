/**
 * @typedef {Object} MutationDeleteOptions
 * @property {String} query - query in cache which should be updated
 * @property {Object} queryVariables - variables for the query being updated. Should be the same ones as when executing query.
 * @property {String} queryListProp - name of propery in cache which holds list of objects. The same value returned from query.
 * @property {String} mutationProp  - name of propery in response which holds the whole mutation data
 * @property {String} mutationObjectProp - name of propery in response which holds the value of new object
 */

/**
 * Removes item from Apollo GraphQL cache
 * @param {MutationDeleteOptions} options
 * @returns {import("apollo-client/core/watchQueryOptions").MutationUpdaterFn} - update callback for Apollo mutation
 */
function mutationDelete(options) {
  const {
    query,
    queryVariables,
    queryListProp,
    mutationProp,
    mutationObjectProp,
  } = options;

  return (cache, response) => {
    const result = response?.data[mutationProp] ?? null;

    if (!result || result.errors.length > 0) return;

    const { [queryListProp]: values = [] } =
      cache.readQuery({
        query,
        variables: queryVariables,
      }) ?? {};

    const data = mutationObjectProp in result && result[mutationObjectProp];
    const index = values.findIndex((x) => x.id === data.id);

    if (index === -1) return;

    values.splice(index, 1);

    cache.writeQuery({
      query,
      variables: queryVariables,
      data: { [queryListProp]: values },
    });
  };
}

export { mutationDelete };
export default mutationDelete;
