import { defineComponent } from "vue";
import { injectActiveEmployee } from "@/mixins/employee";

export default defineComponent({
  name: "ManagerContent",
  functional: true,
  inheritAttrs: false,
  mixins: [injectActiveEmployee],
  render(_, context) {
    if (context.injections.computedActiveEmployeeCan().seeManagerContent) {
      return context.slots().default;
    }
  },
});
