<template>
  <VxDataList
    class="employee-data-list"
    data-testid="employeeDataList"
    :loading="loading"
    :headers="headers"
  >
    <template #body>
      <EmployeeDataListItem
        v-for="employee in sortedEmployees"
        :key="employee.id"
        :employee="employee"
        v-on="$listeners"
        @click="$emit('click:employee', $event)"
      />
    </template>
  </VxDataList>
</template>

<script>
import { sortBy } from "lodash";
import { VxDataList } from "@/core-ui";
import EmployeeDataListItem from "./EmployeeDataListItem.vue";

export default {
  name: "EmployeeDataList",
  components: { VxDataList, EmployeeDataListItem },
  inject: {
    computedActiveEmployeeCan: {
      default: () => {},
    },
  },
  inheritAttrs: false,
  props: {
    employees: {
      type: Array,
      default: () => [],
    },
    query: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sortedEmployees() {
      return sortBy(this.filteredEmployees, (x) => x.fullName?.toLowerCase());
    },
    filteredEmployees() {
      let employees = this.employees.slice();

      if (this.query) {
        const regex = new RegExp(`.*${this.query}.*`, "i");
        employees = employees.filter(
          ({ firstName, lastName, email, phoneNumber, drsEmployeeCode }) =>
            regex.test(firstName) ||
            regex.test(lastName) ||
            regex.test(email) ||
            regex.test(phoneNumber) ||
            regex.test(drsEmployeeCode)
        );
      }

      return employees;
    },
    // TODO: handle manager content
    headers() {
      const managerHeaders = [
        {
          label: "Role",
          propertyPath: "userRoleId",
        },
        {
          label: "Remote access",
          propertyPath: "hasRemoteAccess",
        },
      ];

      return [
        {
          propertyPath: "avatarUrl",
          cssClass: "employee-avatar",
          label: "",
        },
        {
          propertyPath: "fullName",
          label: "Name",
          sortable: true,
        },
        {
          propertyPath: "phoneNumber",
          label: "Phone number",
        },
        { propertyPath: "email", label: "Email" },
        {
          propertyPath: "drsEmployeeCode",
          cssClass: "employee-drs-initials",
          label: "DRS Initials",
        },
        ...(this.computedActiveEmployeeCan().seeManagerContent
          ? managerHeaders
          : []),
      ];
    },
  },
};
</script>

<style lang="scss">
.employee-data-list table {
  th.employee-avatar {
    width: 60px;
  }

  th.employee-drs-initials {
    width: 120px;
  }
}
</style>
