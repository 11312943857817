<template>
  <v-dialog
    v-model="modelValue"
    :max-width="maxWidth"
    :fullscreen="isMobile"
    :scrollable="isScrollable"
    @click:outside="cancel"
    @keydown.esc="cancel"
  >
    <v-card data-testid="employeeDialog" class="rai-scrollbar">
      <v-toolbar short flat dark class="flex-grow-0" color="primary">
        <VxBtn icon @click="cancel">
          <v-icon>$back</v-icon>
        </VxBtn>
        <v-toolbar-title v-if="dialogTitle">
          {{ dialogTitle }}
        </v-toolbar-title>
        <v-spacer />
        <ManagerContent>
          <VxBtn data-testid="employeeDialogEditBtn" icon @click="onEdit">
            <v-fade-transition>
              <v-icon v-if="editable">$editOff</v-icon>
              <v-icon v-else>$edit</v-icon>
            </v-fade-transition>
          </VxBtn>
        </ManagerContent>
      </v-toolbar>
      <v-slide-x-transition hide-on-leave>
        <v-card-text v-if="showForm" :style="formStyle">
          <EmployeeForm ref="form" :employee="employee" @submit="ok" />
        </v-card-text>
        <EmployeeCard v-else :employee="employee" flat />
      </v-slide-x-transition>
      <v-divider v-if="isScrollable && showForm" />
      <v-fade-transition hide-on-leave>
        <ManagerContent>
          <v-card-actions v-if="showForm">
            <template>
              <VxBtn data-testid="employeeDialogSetPinBtn" text @click="setPin">
                Set pin
              </VxBtn>
              <v-spacer />
              <VxBtn data-testid="employeeDialogDeleteBtn" text @click="remove">
                Delete
              </VxBtn>
            </template>
            <VxBtn
              data-testid="employeeDialogSaveBtn"
              :loading="submitting"
              text
              @click="onSave"
            >
              Save
            </VxBtn>
          </v-card-actions>
        </ManagerContent>
      </v-fade-transition>
    </v-card>
  </v-dialog>
</template>

<script>
import EmployeeCard from "./EmployeeCard.vue";
import EmployeeForm from "./EmployeeForm.vue";
import ManagerContent from "@/components/core/ManagerContent";
import { useModelValue, useResponsiveness, VxBtn } from "@/core-ui";

export default {
  name: "EmployeeDialog",
  components: {
    EmployeeCard,
    EmployeeForm,
    ManagerContent,
    VxBtn,
  },
  mixins: [useModelValue(), useResponsiveness()],
  props: {
    title: {
      type: String,
      default: "",
    },
    employee: {
      type: Object,
      default: () => ({}),
    },
    maxWidth: {
      type: Number,
      default: 600,
    },
  },
  data: () => ({
    submitting: false,
    editable: false,
    changed: false,
  }),
  computed: {
    showForm() {
      return !this.employee?.id || this.editable;
    },
    dialogTitle() {
      return this.editable ? this.employee.fullName : this.title;
    },
    isScrollable() {
      return this.$vuetify.breakpoint.lgAndDown;
    },
    formStyle() {
      return {
        height: this.isScrollable ? "500px" : undefined,
      };
    },
  },
  watch: {
    modelValue(value) {
      if (value === false) this.hide();
    },
  },
  methods: {
    onEdit() {
      this.editable = !this.editable;
    },
    onSave() {
      this.$refs.form.submit();
    },
    setPin() {
      this.$emit("set-pin", this.employee.id);
    },
    remove() {
      this.$emit("remove", this.employee);
    },
    cancel() {
      this.hide();
      this.$emit("cancel");
    },
    ok(context) {
      this.submitting = true;

      this.$emit("ok", {
        values: context.values,
        resolve: () => {
          this.hide();
          context.resolve();
        },
        setErrors: (errors) => {
          this.submitting = false;
          context.setErrors(errors);
        },
      });
    },
    hide() {
      this.submitting = false;
      this.modelValue = false;
      this.editable = false;
      this.$refs.form?.reset();
    },
  },
};
</script>
